// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: ProjectDetailsScreen

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useParams, useRoutes } from "react-router-dom";
import { useAppServices } from "../app/services";
import { useCurrentProjectState } from "./CurrentProjectState";
import { PROJECT_SUBROUTE } from "../app/AppRoutes";
import { ProjectDashboardScreens } from "../dashboard/ProjectDashboardScreens";
import { ProjectCmcMigrationsScreens } from "../cmcMigration/ProjectCmcMigrationsScreens";
import { ProjectReportsScreens } from "../reports/ProjectReportsScreen";
import { ProjectManagementScreens } from "../management/ProjectManagementScreens";
import { ProjectSettingsScreens } from "../settings/ProjectSettingsScreens";
import { ProjectIntegrationsScreens } from "../integrations/ProjectIntegrationsScreens";
import { ProjectDeploymentsScreens } from "../deployment/MigrationDeploymentScreens";

import { PrivateEditionLocalNoLicenseWarning } from "../privateEdition/PrivateEditionLocalNoLicenseWarning";
import { PrivateEditionView } from "../auth/PrivateEditionView";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { useGetMyProjectRole, useGetProjectDetails } from "./project_hooks";
import { useEffectOnce } from "react-use";
import { ProjectDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import { GlobalDeploymentsMapScreen } from "../map/GlobalDeploymentsMapScreen";
import { ProjectMigrateOpsScreens } from "../migrateops/ProjectMigrateOpsScreens";
import { CmoMigrationsScreens } from "../cmoMigration/CmoMigrationsScreens";
import { MigrateOpsDocsView } from "../migrateops/MigrateOpsDocsView";
import { MigrateOpsGettingStartedInstructionsView } from "../migrateops/MigrateOpsGettingStartedInstructionsView";

// ======================
// ProjectDetailsScreen
// ======================
interface ProjectDetailsScreenProps {}

export const ProjectDetailsScreen: React.FC<ProjectDetailsScreenProps> = observer((p) => {
    const { projectId } = useParams();
    const { deploymentService, licenseService, gmDeploymentService, gmMigrationService, projectService } = useAppServices();
    const clearCurrentProject = useCurrentProjectState((s) => s.clearCurrentProject);
    const init = () => {
        gmDeploymentService.galaxyMigrateLinks.resetData();
        gmMigrationService.sessions.resetData();
        projectService.clearCurrentProject();
        clearCurrentProject();
    };

    useEffectOnce(() => {
        init();
    });

    const queryResult = useGetProjectDetails(projectId);
    const myProjectRole = useGetMyProjectRole(projectId);

    return (
        <QueryResultWrapper queryResult={queryResult}>
            <PrivateEditionView>
                <PrivateEditionLocalNoLicenseWarning />
            </PrivateEditionView>
            <ProjectDetailsRoutes currentProject={queryResult.data} />
        </QueryResultWrapper>
    );
});

// ======================
// ProjectDetailsRoutes
// ======================
interface ProjectDetailsRoutesProps {
    currentProject: ProjectDetails;
}

const ProjectDetailsRoutes: React.FC<ProjectDetailsRoutesProps> = observer((p) => {
    const { currentProject } = p;

    // sub routes will be implemented underneath
    return useRoutes([
        {
            path: "/",
            element: <Navigate to={PROJECT_SUBROUTE.MANAGEMENT} replace />,
        },
        {
            path: PROJECT_SUBROUTE.MANAGEMENT + "/*",
            element: <ProjectManagementScreens project={currentProject} />,
        },
        {
            path: PROJECT_SUBROUTE.DASHBOARD + "/*",
            element: <ProjectDashboardScreens project={currentProject} />,
        },
        {
            path: PROJECT_SUBROUTE.GLOBAL_MAP_VIEW + "/*",
            element: <GlobalDeploymentsMapScreen projectId={currentProject.getInfo().getProjectId()} />,
        },
        {
            path: PROJECT_SUBROUTE.CMC_MIGRATIONS + "/*",
            element: <ProjectCmcMigrationsScreens project={currentProject} />,
        },
        {
            path: PROJECT_SUBROUTE.CMO_MIGRATIONS + "/*",
            element: <CmoMigrationsScreens projectId={currentProject.getInfo().getProjectId()} />,
        },
        {
            path: PROJECT_SUBROUTE.MIGRATE_OPS + "/*",
            element: <ProjectMigrateOpsScreens />,
        },
        {
            path: PROJECT_SUBROUTE.MIGRATE_OPS_DOCS + "/*",
            element: <MigrateOpsDocsView />,
        },
        {
            path: PROJECT_SUBROUTE.REPORTS + "/*",
            element: <ProjectReportsScreens />,
        },
        {
            path: PROJECT_SUBROUTE.INTEGRATIONS + "/*",
            element: <ProjectIntegrationsScreens project={currentProject} />,
        },
        {
            path: PROJECT_SUBROUTE.SETTINGS + "/*",
            element: <ProjectSettingsScreens project={currentProject} />,
        },
    ]);
});
